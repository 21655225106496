import Link from "next/link"
import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"
import SearchBar from "components/SearchBar"

export default function Preheader({ preheader }) {
  return (
    <div className="hidden bg-teal-dark px-6 py-3 lg:block">
      <div className="mx-auto flex max-w-screen-xl items-center justify-between gap-12">
        <SearchBar expanded={false} variant="light" />
        <div className="flex items-center divide-x-2 leading-none text-white">
          {preheader?.menu_items.map((item) => {
            const href = getStoryblokLink(item?.link)
            return (
              <Link
                href={href}
                target={isInternalLink(href) ? "_self" : "_blank"}
                key={item?.label}
                className="px-4 font-secondary font-medium last:pr-0 hover:underline"
              >
                {item?.label}
              </Link>
            )
          })}
          <CallToAction href="/donate-online" style="small" className="ml-2">
            Donate Online
          </CallToAction>
        </div>
      </div>
    </div>
  )
}
