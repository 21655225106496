import { useState } from "react"
import cn from "classnames"
import MagnifyingGlass from "public/assets/magnifying-glass.svg"
import { useRouter } from "next/router"

export default function SearchBar({ expanded: _expanded, variant = "light" }) {
  const [expanded, setExpanded] = useState(_expanded)
  const router = useRouter()

  return (
    <form
      name="search"
      className={cn("relative font-tertiary", {
        "text-white": variant === "light",
        "text-teal-dark": variant === "dark",
      })}
      onSubmit={(e) => {
        e.preventDefault()

        if (!expanded) {
          return setExpanded(true)
        }

        const { value } = e.target.query
        if (expanded && value !== "") {
          setExpanded(false)
          router.push(`/search?q=${value}`)
          e.target.query.value = ""

          return
        }
      }}
    >
      <label className="sr-only" htmlFor="search">
        Search
      </label>
      <button
        type="submit"
        tabIndex={expanded ? "-1" : null}
        className={cn("absolute left-[11px] top-0 h-full", {
          "pointer-events-none cursor-default": expanded,
        })}
        aria-label="toggle search form visibility"
      >
        <MagnifyingGlass className="cursor-pointer" />
      </button>
      <input
        id="search"
        name="query"
        required={expanded ? true : false}
        aria-label="search input"
        className={cn("block rounded-none rounded-br-xl border-2 py-2 pl-9 pr-2 transition-all placeholder:italic", {
          "border-white bg-transparent placeholder:text-white": variant === "light",
          "border-teal-dark placeholder:text-teal-dark": variant === "dark",
          "w-full": expanded,
          "w-2": !expanded,
        })}
        onFocus={() => setExpanded(true)}
        onBlur={() => setExpanded(false)}
        type="search"
        placeholder="Search our site"
      />
    </form>
  )
}
