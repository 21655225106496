import SbEditable from "storyblok-react"
import dynamic from "next/dynamic"

// resolve Storyblok components to Next.js components
export const Components = {
  // Atoms
  accordion_item: dynamic(() => import("./atoms/AccordionItem")),
  button: dynamic(() => import("./atoms/StoryblokButton")),
  cta_banner: dynamic(() => import("./atoms/CTABanner")),
  content: dynamic(() => import("./atoms/Content")),
  featured_list: dynamic(() => import("./atoms/FeaturedList")),
  header_image: dynamic(() => import("./atoms/HeaderImage")),
  header_video: dynamic(() => import("./atoms/HeaderVideo")),
  icon_card: dynamic(() => import("./atoms/IconCard")),
  inline_logo_content: dynamic(() => import("./atoms/InlineLogoAndContent")),
  three_column_list: dynamic(() => import("./atoms/ThreeColumnList")),
  raisers_edge_embed: dynamic(() => import("./atoms/RaisersEdgeEmbed")),
  bamboo_hr_embed: dynamic(() => import("./atoms/BambooHREmbed")),

  // Molecules
  event_section: dynamic(() => import("./molecules/EventSection")),
  faq: dynamic(() => import("./molecules/FAQSection")),
  form_embed_section: dynamic(() => import("./molecules/FormEmbedSection")),
  icon_card_grid_section: dynamic(() => import("./molecules/IconCardGridSection")),
  hero: dynamic(() => import("./molecules/Hero")),
  image_and_content: dynamic(() => import("./molecules/ImageAndContent")),
  map_and_tile_section: dynamic(() => import("./molecules/MapAndTileSection")),
  page_intro: dynamic(() => import("./molecules/PageIntro")),
  photo_card: dynamic(() => import("./molecules/PhotoCard")),
  two_column_section: dynamic(() => import("./molecules/TwoColumnSection")),
  two_column_sidebar: dynamic(() => import("./molecules/TwoColumnSidebar")),
  timeline_section: dynamic(() => import("./molecules/TimelineSection")),
  floating_section: dynamic(() => import("./molecules/FloatingSection")),
  affiliate_card: dynamic(() => import("./molecules/AffiliateCard")),
  team_member_section: dynamic(() => import("./molecules/TeamMemberSection")),
  card_section: dynamic(() => import("./molecules/CardSection")),
  two_column_content: dynamic(() => import("./molecules/TwoColumnContent")),
  program_overview: dynamic(() => import("./molecules/ProgramOverview")),
  program_info: dynamic(() => import("./molecules/ProgramInfo")),
  featured_team_member: dynamic(() => import("./molecules/FeaturedTeamMember")),
  two_button: dynamic(() => import("./molecules/TwoButton")),
  image_gallery: dynamic(() => import("./molecules/ImageGallerySection")),
  icon_card_overlay: dynamic(() => import("./molecules/IconCardOverlay")),
  mini_card_section: dynamic(() => import("./molecules/MiniCardSection")),
  support_section: dynamic(() => import("./molecules/SupportSection")),

  // Organisms
  page: dynamic(() => import("./organisms/Page")),
  colored_background_section: dynamic(() => import("./organisms/ColoredBackgroundSection")),
}

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok?.component] !== "undefined") {
    const Component = Components[blok.component]
    // wrap with SbEditable for visual editing

    // Set an _editable value to avoid error in SbEditable
    if (blok._editable === null) {
      blok._editable = undefined
    }
    if (typeof window !== "undefined" && window.location.search.includes("_storyblok")) {
      return (
        <SbEditable content={blok}>
          <Component blok={blok} />
        </SbEditable>
      )
    }

    return <Component blok={blok} />
  }

  // fallback if the component doesn't exist
  return null
}

export default DynamicComponent
