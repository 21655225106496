import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"

export default function TopLevelMenuItem({ item, depth = 1, currentPath, submenuOpen, setSubmenuOpen }) {
  const href = getStoryblokLink(item?.link)

  return (
    <li
      aria-label={item?.label}
      onClick={(e) => {
        e.stopPropagation()
        if (depth === 1) {
          setSubmenuOpen((prev) => (prev !== item?.label ? item?.label : ""))
        }
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          setSubmenuOpen((prev) => (prev !== item?.label ? item?.label : ""))
        }
      }}
      tabIndex="0"
    >
      {href ? (
        <Link
          href={href}
          className={cn("cursor-pointer whitespace-nowrap", {
            "current-open-menu": submenuOpen === item?.label,
            "current-page": currentPath === href,
          })}
          target={isInternalLink(href) ? "_self" : "_blank"}
        >
          {item?.label}
        </Link>
      ) : (
        <div
          aria-label={item?.label}
          className={cn("nonlinked", {
            "current-open-menu": submenuOpen === item?.label,
          })}
        >
          {item?.label}
        </div>
      )}
    </li>
  )
}
