import { useState } from "react"
import Link from "next/link"
import cn from "classnames"
import getTelLink from "utilities/getTelLink"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"
import Briefcase from "public/assets/briefcase.svg"
import MapPin from "public/assets/header-map-pin.svg"
import Phone from "public/assets/header-phone.svg"
import MagnifyingGlass from "public/assets/magnifying-glass.svg"

export default function CallToActionSubMenu({ ctaSubMenu, phoneNumbers }) {
  const [openDrawer, setOpenDrawer] = useState(false)

  function Icon({ link, label, children }) {
    return (
      <li>
        <Link href={link} className="flex flex-col items-center gap-1 font-secondary leading-none text-white">
          <span className="h-8">{children}</span>
          {label}
        </Link>
      </li>
    )
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 z-[110] lg:relative">
      <div className="group relative bg-springWood">
        <button
          className={cn(
            "cta-sub-menu flex w-full justify-between gap-2 whitespace-nowrap border-[3px] border-green bg-green px-8 py-2 text-left font-secondary text-m1 font-bold uppercase text-teal-dark lg:w-auto lg:rounded-full lg:px-6 lg:py-0 lg:hover:border-teal-dark lg:hover:bg-teal-dark lg:hover:text-green",
            {
              open: openDrawer,
            },
          )}
          onClick={() => setOpenDrawer(!openDrawer)}
          onKeyDown={(e) => {
            if (e.code === "Return") {
              setOpenDrawer(!openDrawer)
            }
          }}
        >
          I Want To
        </button>
        <ul
          className={cn(
            "right-0 w-full origin-top divide-y overflow-hidden bg-white px-8 transition-all duration-500 lg:absolute lg:w-max lg:scale-y-0 lg:border-t-4 lg:border-green lg:py-5 lg:text-right lg:shadow-[0px_4px_4px_rgba(0,0,0,0.25)] lg:focus-within:scale-y-100 lg:group-hover:scale-y-100",
            {
              "max-h-0 opacity-0 lg:max-h-max lg:opacity-100": !openDrawer,
              "lg:max-h-auto max-h-[500px] py-3 opacity-100": openDrawer,
            },
          )}
        >
          {ctaSubMenu?.menu_items?.map((item) => {
            const href = getStoryblokLink(item?.link)

            return (
              <li key={`menu-item-${item?._uid}`}>
                <Link
                  href={href}
                  target={isInternalLink(href) ? "_self" : "_blank"}
                  className="font-secondary text-m1 font-semibold uppercase leading-9 text-teal-dark hover:underline"
                  tabIndex={openDrawer ? "0" : "-1"}
                >
                  {item?.label}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="bg-teal-dark px-4 py-3 lg:hidden">
        <ul className="grid w-full grid-cols-4 justify-items-center">
          <Icon link="/get-involved/join-our-team" label="Careers">
            <Briefcase />
          </Icon>
          <Icon link="/learn-more/contact-us" label="Directions">
            <MapPin />
          </Icon>
          <Icon link={getTelLink(phoneNumbers?.Main)} label="Contact">
            <Phone />
          </Icon>
          <Icon link="/search" label="Search">
            <MagnifyingGlass />
          </Icon>
        </ul>
      </div>
    </div>
  )
}
