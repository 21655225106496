import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import cn from "classnames"
import TopLevelMenuItem from "components/Header/TopLevelMenuItem"
import CallToActionSubMenu from "components/Header/CallToActionSubMenu"
import Preheader from "components/Header/Preheader"
import SubMenu from "components/Header/SubMenu"
import MenuButton from "public/assets/hamburger-menu.svg"
import useWindowSize from "utilities/useWindowSize"
import Logo from "public/assets/logo.svg"

export default function Header({ header, preheader, ctaSubMenu, phoneNumbers }) {
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false)
  const [submenuOpen, setSubmenuOpen] = useState("")
  const [openSecondLevelMenu, setOpenSecondLevelMenu] = useState("")
  const [openThirdLevelMenu, setOpenThirdLevelSubmenu] = useState("")

  const router = useRouter()

  const { width } = useWindowSize()

  // close the menu when a link is clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setMobileMenuVisibility(false)
      setSubmenuOpen("")
      setOpenSecondLevelMenu("")
      setOpenThirdLevelSubmenu("")
      document.activeElement && document.activeElement.blur()
    }
    router.events.on("routeChangeStart", handleRouteChange)
    router.events.on("hashChangeStart", handleRouteChange)

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
      router.events.off("hashChangeStart", handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (mobileMenuVisibility === false && width < 1024) {
      setSubmenuOpen("")
      setOpenSecondLevelMenu("")
      setOpenThirdLevelSubmenu("")
    }
    if (mobileMenuVisibility === true && width >= 1024) {
      setMobileMenuVisibility(false)
      setSubmenuOpen("")
      setOpenSecondLevelMenu("")
      setOpenThirdLevelSubmenu("")
    }
    if (submenuOpen === "" && width >= 1024) {
      setOpenSecondLevelMenu("")
      setOpenThirdLevelSubmenu("")
    }
  }, [mobileMenuVisibility, submenuOpen, openSecondLevelMenu, openThirdLevelMenu, width])

  // disable scroll when mobile menu is open
  useEffect(() => {
    document.getElementById("__next").style.overflow = mobileMenuVisibility ? "hidden" : ""
    document.querySelector("html").style.overflow = mobileMenuVisibility ? "hidden" : ""
  }, [mobileMenuVisibility])

  // update height of submenu
  useEffect(() => {
    if (width) {
      const submenu = document.querySelector(".submenu")
      if (width >= 1024) {
        const thirdLevelMenu = document.querySelector(
          ".submenu > ul > li.open-whole-submenu > ul > li.open-third-submenu + .third-level-items",
        )
        if (openThirdLevelMenu !== "") {
          submenu.style.height = submenu.contains(thirdLevelMenu) ? `${thirdLevelMenu.clientHeight + 96}px` : "auto"
        } else {
          submenu.style.height = "auto"
        }
      } else {
        submenu.style.height = "85dvh"
      }
    }
  }, [openThirdLevelMenu, submenuOpen, width])

  function updateSubmenu(depth, items, label) {
    if (depth === 1 && items && items.length > 0) {
      setOpenSecondLevelMenu((prev) => (prev !== label ? label : ""))
    }
    if (depth === 2 && items && items.length > 0) {
      setOpenThirdLevelSubmenu((prev) => (prev !== label ? label : ""))
    }
  }

  return (
    <header className="lg:relative">
      <Preheader preheader={preheader} />
      <div className="px-4 py-4 shadow sm:px-8 sm:py-6">
        <div className="mx-auto flex max-w-screen-xl items-center justify-between gap-7">
          <Link href="/">
            <Logo className="header-logo h-[67px] max-w-[145px] sm:max-w-[212px]" alt="Header logo" />
          </Link>
          <button
            className="lg:hidden"
            aria-label={mobileMenuVisibility ? "close menu" : "open menu"}
            onClick={() => setMobileMenuVisibility((prev) => !prev)}
          >
            <MenuButton className={cn("mobile-menu-btn cursor-pointer", { open: mobileMenuVisibility })} />
          </button>
          <div
            className={cn(
              "absolute bottom-0 left-0 z-[9999] w-full origin-top gap-6 transition-all duration-500 lg:static lg:flex lg:scale-y-100 lg:flex-col lg:gap-0",
              {
                "scale-y-0": !mobileMenuVisibility,
                "scale-y-100": mobileMenuVisibility,
              },
            )}
          >
            <nav aria-label="Main" className="top-level-menu flex w-full flex-col lg:flex-row lg:justify-end">
              <ul aria-label="Top-level Menu Items" className="m-0 hidden lg:flex lg:items-center">
                {header?.menu_items?.map((item) => (
                  <TopLevelMenuItem
                    {...{ submenuOpen, setSubmenuOpen, item, currentPath: router.asPath }}
                    key={`top-level-menu-item-${item._uid}`}
                  />
                ))}
              </ul>
              <CallToActionSubMenu ctaSubMenu={ctaSubMenu} phoneNumbers={phoneNumbers} />
            </nav>
          </div>
        </div>
      </div>
      <nav aria-label="Submenu" className="submenu max-h-screen shadow-[0px_2px_5px_0px] shadow-gray-mid">
        <SubMenu
          {...{
            menuItems: header?.menu_items,
            currentPath: router.asPath,
            submenuOpen,
            openThirdLevelMenu,
            setOpenThirdLevelSubmenu,
            openSecondLevelMenu,
            setOpenSecondLevelMenu,
            updateSubmenu,
            mobileMenuVisibility,
          }}
        />
      </nav>
    </header>
  )
}
