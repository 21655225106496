import { Fragment } from "react"
import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"
import richText from "utilities/richText"

export default function SubMenu({
  menuItems,
  currentPath,
  mobileMenuVisibility,
  submenuOpen,
  openSecondLevelMenu,
  openThirdLevelMenu,
  updateSubmenu,
  depth = 1,
}) {
  return depth <= 3 && menuItems && menuItems?.length > 0 ? (
    <ul>
      {menuItems.map((item) => {
        const href = getStoryblokLink(item?.link)
        return (
          <Fragment key={`menu-item-${item._uid}`}>
            <li
              aria-label={item?.label}
              className={cn({
                "open-whole-submenu": submenuOpen === item?.label && !mobileMenuVisibility && depth === 1,
                "closed-whole-submenu": submenuOpen !== item?.label && !mobileMenuVisibility && depth === 1,
                "closed-second-submenu": openSecondLevelMenu !== item?.label && mobileMenuVisibility && depth === 1,
                "closed-third-submenu": openThirdLevelMenu !== item?.label && mobileMenuVisibility && depth === 2,
                "open-second-submenu": openSecondLevelMenu === item?.label && mobileMenuVisibility && depth === 1,
                "open-third-submenu": openThirdLevelMenu === item?.label && depth === 2,
              })}
              onClick={(e) => {
                e.stopPropagation()
                updateSubmenu(depth, item?.nested_menu_items, item?.label)
              }}
              onKeyDown={(e) => {
                e.stopPropagation()
                if (e.code === "Enter") {
                  updateSubmenu(depth, item?.nested_menu_items, item?.label)
                }
              }}
            >
              <div className="basis-1/3 cursor-pointer">
                {href ? (
                  <Link
                    href={href}
                    className={cn("cursor-pointer whitespace-nowrap", {
                      "current-page": currentPath === href,
                    })}
                    target={isInternalLink(href) ? "_self" : "_blank"}
                  >
                    {item?.label}
                  </Link>
                ) : (
                  <div aria-label={item?.label} className="nonlinked" tabIndex="0">
                    {item?.label}
                  </div>
                )}
                {depth === 1 && item.content ? (
                  <div className="hidden cursor-auto lg:block">{richText(item.content)}</div>
                ) : null}
              </div>
              {depth === 1 && item?.nested_menu_items && item?.nested_menu_items.length > 0 ? (
                <SubMenu
                  {...{
                    openThirdLevelMenu,
                    updateSubmenu,
                    submenuOpen,
                    mobileMenuVisibility,
                    openSecondLevelMenu,
                    menuItems: item?.nested_menu_items,
                    depth: depth + 1,
                  }}
                />
              ) : null}
            </li>
            {depth === 2 && item?.nested_menu_items && item?.nested_menu_items.length > 0 ? (
              <div className="third-level-items">
                <SubMenu
                  {...{
                    openThirdLevelMenu,
                    updateSubmenu,
                    submenuOpen,
                    mobileMenuVisibility,
                    openSecondLevelMenu,
                    menuItems: item?.nested_menu_items,
                    depth: depth + 1,
                  }}
                />
              </div>
            ) : null}
          </Fragment>
        )
      })}
    </ul>
  ) : null
}
