import NextLink from "next/link"
import cn from "classnames"
import isInternalLink from "utilities/isInternalLink"

export default function CallToAction({
  href,
  target = null,
  children,
  style = "primary",
  button = false,
  className,
  ...delegated
}) {
  const ctaStyles = {
    primary:
      "primary-link inline-block px-9 py-3 text-m3 sm:text-2xl font-bold font-secondary uppercase text-center rounded-full no-underline text-white bg-orange hover:bg-green hover:text-teal-dark border-[3px] border-orange hover:border-green",
    secondary:
      "secondary-link inline-block px-9 py-3 text-m3 sm:text-2xl font-bold font-secondary uppercase text-white text-center border-white border-[3px] rounded-full no-underline hover:bg-white hover:text-teal-dark whitespace-nowrap",
    tertiary:
      "tertiary-link inline-block px-9 py-3 text-m3 sm:text-2xl font-bold font-secondary uppercase text-orange text-center border-orange border-[3px] rounded-full no-underline hover:bg-orange hover:text-white whitespace-nowrap",
    small:
      "small-link inline-block px-6 text-m1 font-bold uppercase text-center rounded-full no-underline text-white bg-orange border-[3px] border-orange hover:bg-green hover:text-teal-dark hover:border-green",
    inline: "inline-link inline text-base text-tertiary underline text-teal-dark font-medium hover:no-underline",
  }
  const styles = cn(
    "inline-block cursor-pointer max-w-full transition-all duration-300", // default styles
    ctaStyles[style],
    className,
  )

  if (button) {
    return (
      <button className={styles} {...delegated}>
        {children}
      </button>
    )
  }

  if (!href)
    return (
      <span data-type="invalid-link" className={styles}>
        {children}
      </span>
    )

  const internal = isInternalLink(href)

  target = target ?? internal ? "_self" : "_blank"

  if (href.startsWith("tel:") || href.startsWith("mailto:")) {
    target = "_self"
  }

  return internal ? (
    <NextLink href={href} data-type="route" className={styles} {...delegated}>
      {children}
    </NextLink>
  ) : (
    <a data-type="external" className={styles} target={target} href={href} {...delegated}>
      {children}
    </a>
  )
}
